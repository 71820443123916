import React from "react";
import { Container, Nav } from "react-bootstrap";
import Logoheader from "../header/Logoheader";
import SocialDetails from "../social-details/SocialDetails";
import "./comingsoon.css";
import subscriptions from "../../Components/assets/subscriptions-icon.svg";
import Warranties from "../../Components/assets/Warranties-icon.svg";
import Giftcards from "../../Components/assets/Giftcards-icon.svg";
import Compare_Save from "../../Components/assets/Compare_&_Save-icon.svg";
import Benefits from "../../Components/assets/Benefits-icon.svg";
import Tasks from "../../Components/assets/Tasks-icon.svg";
import FooterBottom from "../footer/FooterBottom";

const Comingsoon = () => {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Logoheader />
      <section className="coming-soon text-center">
        <Container>
          <div className="info-soon">
            <h1 className="ft-bold mb-4 pt-5">Something Exciting is coming.</h1>
            <p>Supporting the elderly, their families, and their loved ones.</p>
          </div>

          {/* <Nav
            className="justify-content-md-evenly mt-5 flex-column flex-md-row"
            activeKey="/home"
          >
            <Nav.Item>
              <img alt="elderly life subscriptions" src={subscriptions} />
              <h6 className="theme-color ft-bold mt-2 mb-3 mb-md-0">
                Subscriptions
              </h6>
            </Nav.Item>
            <Nav.Item>
              <img alt="elderly life subscriptions" src={Warranties} />
              <h6 className="theme-color ft-bold mt-2 mb-3 mb-md-0">
                Warranties
              </h6>
            </Nav.Item>
            <Nav.Item>
              <img alt="elderly life subscriptions" src={Giftcards} />
              <h6 className="theme-color ft-bold mt-2 mb-3 mb-md-0">
                Giftcards
              </h6>
            </Nav.Item>
            <Nav.Item>
              <img alt="elderly life subscriptions" src={Compare_Save} />
              <h6 className="theme-color ft-bold mt-2 mb-3 mb-md-0">
                Compare & Save
              </h6>
            </Nav.Item>
            <Nav.Item>
              <img alt="elderly life subscriptions" src={Benefits} />
              <h6 className="theme-color ft-bold mt-2 mb-3 mb-md-0">
                Benefits
              </h6>
            </Nav.Item>
            <Nav.Item>
              <img alt="elderly life subscriptions" src={Tasks} />
              <h6 className="theme-color ft-bold mt-2 mb-3   mb-md-0">Tasks</h6>
            </Nav.Item>
          </Nav> */}
        </Container>
      </section>
      {/* <SocialDetails />   */}
      <FooterBottom />
    </>
  );
};

export default Comingsoon;
