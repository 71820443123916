import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Header from "../../header/Header";
import elderlyImg from "../../assets/elderlyLifeImg.png";
import pic1 from "../../assets/Picture11.svg";
import pic2 from "../../assets/Picture12.svg";

import "./plan.css";
import { Link } from "react-router-dom";
import Loader from "../../loader/Loader";

const Plan = () => {
  const [key, setKey] = useState("Annual");
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div class="container-fluid px-5">
            <Row>
              <Col md="6">
                <img
                  alt="Planning & Organization tool for the elderly"
                  src={elderlyImg}
                  className="img-fluid"
                  style={{ width: "20rem" }}
                />
              </Col>
              <Col md="6" className="py-3 px-0">
                <div className="plan1">
                  <span
                    style={{
                      color: "grey",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    Giving
                  </span>
                  <span
                    style={{
                      color: "black",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    You
                  </span>
                  <span
                    style={{
                      color: "#36f1cc",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    Control
                  </span>
                </div>
                <div className="plan2">
                  <span
                    style={{
                      color: "grey",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    Keeping
                  </span>
                  <span
                    style={{
                      color: "black",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    You
                  </span>
                  <span
                    style={{
                      color: "#36f1cc",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Reminded
                  </span>
                </div>
                <div className="plan3">
                  <span
                    style={{
                      color: "grey",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    Saving
                  </span>
                  <span
                    style={{
                      color: "black",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    You
                  </span>
                  <span
                    style={{
                      color: "#36f1cc",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Money
                  </span>
                </div>
                <div className="plan4">
                  <span
                    style={{
                      color: "grey",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    Providing
                  </span>
                  <span
                    style={{
                      color: "black",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    You
                  </span>
                  <span
                    style={{
                      color: "#36f1cc",
                      padding: "0 0.25rem",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Support
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="my-5">
              <Col xxl={9} xl={9} lg={9}>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-5  plan-tabs"
                >
                  <Tab eventKey="Annual" title="Annual" className="mt-3">
                    <Row>
                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Single</div>
                          <div className="sub-box1">
                            <div className="text1-yearly">£29.99/yearly</div>
                            <div className="text2-yearly">
                              <s>£47.88</s>
                            </div>
                            <div className="mb-3">
                              Pay yearly and save <b>£17.89</b>
                            </div>
                            <div className="text2-yearly">1 Main User</div>
                            <div className="text2-yearly">1 Support User</div>
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Couple</div>
                          <div className="sub-box1">
                            <div className="text1-yearly">£49.99/yearly</div>
                            <div className="text2-yearly">
                              <s>£71.88</s>
                            </div>
                            <div className="mb-3">
                              Pay yearly and save <b>£21.89</b>
                            </div>
                            <div className="text2-yearly">2 Main User</div>
                            <div className="text2-yearly">
                              2 Support User
                            </div>{" "}
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Family</div>
                          <div className="sub-box-1">Best Value</div>

                          <div className="sub-box1">
                            <div className="text1-yearly">£69.99/yearly</div>
                            <div className="text2-yearly">
                              <s>£95.88</s>
                            </div>
                            <div className="mb-3">
                              Pay yearly and save <b>£25.89</b>
                            </div>
                            <div className="text2-yearly">5 Main User</div>
                            <div className="text2-yearly">5 Support User</div>
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Monthly" title="Monthly" className="mt-3">
                    <Row>
                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Single</div>
                          <div className="sub-box1">
                            <div className="text1-monthly">£3.99 a month</div>
                            <div className="text2-monthly">1 Main User</div>
                            <div className="text2-monthly">1 Support User</div>
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Couple</div>
                          <div className="sub-box1">
                            <div className="text1-monthly">£5.99 a month</div>
                            <div className="text2-monthly">2 Main User</div>
                            <div className="text2-monthly">2 Support User</div>
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4} className="mb-3 mb-md-0">
                        <div className="main-box">
                          <div className="sub-box">Family</div>
                          <div className="sub-box-1">Best Value</div>
                          <div className="sub-box1">
                            <div className="text1-monthly">£7.99 a month</div>
                            <div className="text2-monthly">5 Main User</div>
                            <div className="text2-monthly">5 Support User</div>
                            <div className="mt-3">
                              <a
                                href="https://dev.elderlylife.co.uk/signup"
                                className="tryBtn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Try Free for 7 days
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Col>
              <Col xxl={3} xl={3} lg={3} className="d-flex flex-column m-auto">
                <div className="plan-details">
                  <img src={pic1} alt="pic1" style={{ width: "4rem" }} />
                  <div>
                    <b>Free Cancellation</b>
                    <div>We want you happy – cancel anytime we no stress</div>
                  </div>
                </div>
                <div className="plan-details">
                  <img src={pic2} alt="pic2" style={{ width: "4rem" }} />
                  <div>
                    <b>No hidden or additional fees</b>
                    <div>
                      We are completely transparent – no other costs apart from
                      your subscription
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default Plan;
