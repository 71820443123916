import React, { useEffect, useRef, useState } from "react";
// import  from react;
import { Container, Row, Col, Button, Card, Badge, Nav } from "react-bootstrap";
import "./home.css";
import Header from "../../../Components/header/Header";
// import Footer from "../../../Components/footer/Footer";
// import Elimg from "../../assets/Planning-&-Organization-tool.jpg";
// import subscription from "../../assets/subscriptionempty.svg";
// import warranties from "../../assets/warranties-empty.svg";
// import giftcards from "../../assets/gift-cards-empty.svg";
// import task from "../../assets/task-empty.svg";
// import benefits from "../../assets/benefits-empty.svg";
// import comparesave from "../../assets/compare-save.svg";
// import { Link } from "react-router-dom";
import elderlyImg from "../../assets/elderlyLifeImg.png";
import { Link } from "react-router-dom";
import Loader from "../../loader/Loader";

const Home = () => {
  const helpservices = useRef(null);

  const handlescroll = () => {
    window.scrollTo({
      top: helpservices.current.offsetTop,
      behavior: "smooth",
    });
  };

  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  // hello
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="homepage">
          <section className="hero-page">
            <Container>
              <Row>
                <Col lg={5} className="mb-4 mb-lg-0">
                  <div className="caption">
                    <div>
                      <span className="family-matters">#Family Matters</span>
                      <h1>Planning & Organization tool</h1>
                      <p className="text-style">
                        For the elderly and their families
                      </p>
                      {/* <Button
                      variant="info"
                      className="custom-btn"
                      onClick={handlescroll}
                    >
                      Join Now for Free
                    </Button> */}
                      <Link className="newBtn" to="/plans">
                        Join Now for Free
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={7}>
                  <img
                    alt="Planning & Organization tool for the elderly"
                    src={elderlyImg}
                    className="img-fluid"
                    style={{ width: "25rem" }}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="info-data">
            <Container>
              {/* <Row>
              <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                <h3 className="theme-head">1000+</h3>
                <p>Active users on platform</p>
              </Col>
              <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                <h3 className="theme-head">£ 100+ Million</h3>
                <p>Saved this year</p>
              </Col>
              <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                <h3 className="theme-head">400+ Insurances</h3>
                <p>Renewed on time</p>
              </Col>
              <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                <h3 className="theme-head">6766+</h3>
                <p>Reminders every month</p>
              </Col>
            </Row> */}
              <Row>
                <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                  <div className="footer-box">
                    £150 is lost per year due to failure of cancelling
                    subscriptions
                  </div>
                </Col>
                <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                  <div className="footer-box">
                    On average £701 could be saved by using the comparison tool
                    for Car, Home and Travel Insurance
                  </div>
                </Col>
                <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                  <div className="footer-box">
                    20% of pensioners in the UK have unused vouchers worth an
                    average of £100
                  </div>
                </Col>
                <Col xs={6} lg={3} className="mb-3 mb-lg-0">
                  <div className="footer-box">
                    1 in 3 pensioners in the UK forget to pick up their
                    important prescriptions
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="footer-text">
                  The Elderly Life Platform allows you to spend more time on the
                  things you love
                </div>
              </Row>
            </Container>
          </section>
          {/* <section className="help-services" ref={helpservices}>
          <Container>
            <div className="text-center mb-5">
              <h3 className="theme-head mb-2">How we help you?</h3>
              <p>We help you manage you expences in a meaningful way</p>
            </div>
            <Row>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0 align-items-md-center align-items-lg-start">
                  <Card.Img
                    alt="subscription"
                    variant="top"
                    src={subscription}
                  />
                  <Card.Body>
                    <Card.Title className="theme-head2">Outgoings</Card.Title>
                    <Card.Text>
                      You will never miss a renewal date for any of the
                      Outgoings you use.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0">
                  <Card.Img alt="warranties" variant="top" src={warranties} />
                  <Card.Body>
                    <Card.Title className="theme-head2">Warranties</Card.Title>
                    <Card.Text>
                      Stay relieved when all your warranties are handled by us
                      at one place.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0">
                  <Card.Img alt="giftcards" variant="top" src={giftcards} />
                  <Card.Body>
                    <Card.Title className="theme-head2">
                      Gift cards & Vouchers
                    </Card.Title>
                    <Card.Text>
                      Never lose the benefits of a gift, by keeping a steady
                      track on it.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0">
                  <Card.Img alt="task" variant="top" src={task} />
                  <Card.Body>
                    <Card.Title className="theme-head2">To Do's</Card.Title>
                    <Card.Text>
                      Stay on top of your important To Do's with timely
                      reminders on the platform.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0">
                  <Card.Img alt="benefits" variant="top" src={benefits} />
                  <Card.Body>
                    <Card.Title className="theme-head2">Benefits</Card.Title>
                    <Card.Text>
                      Get the exclusive list of benefits curated towards your
                      spending trends.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                <Card className="d-flex flex-row border-0">
                  <Card.Img
                    alt="compare and save"
                    variant="top"
                    src={comparesave}
                  />
                  <Card.Body>
                    <Card.Title className="theme-head2">
                      Compare and Save
                    </Card.Title>
                    <Card.Text>
                      Stay timely reminded with all your important To Do's you
                      have added on the platform.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="el-management">
          <Container>
            <div className="text-center mb-5">
              <h3 className="theme-head mb-3">
                Our Efficient Management System
              </h3>
              <p>
                We understand that managing the spends is a lot for elderly life
                and hence we have made
                <span className="d-block">
                  a new engagement model with different users on the Elderly
                  Life platform.
                </span>
              </p>
            </div>
            <div></div>
          </Container>
          <div className="family-management">
            <Container>
              <h3 className="theme-head">A Family</h3>
              <Row>
                <Col lg={6}>
                  <div className="pe-4">
                    <p className="mt-2 mt-lg-3">
                      Any User on the Elderly Life platform would be in the
                      context of a Family. A User can be part of multiple
                      Families with different roles in each of them.
                    </p>
                    <div className="image-res mb-4 mb-lg-0"></div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="d-flex align-items-center mb-3 mb-md-4 mb-xxl-5">
                    <Badge className="d-flex align-items-center">
                      <h2>AO</h2>
                    </Badge>
                    <p className="para-list">
                      As an Admin User you will be the in-charge of the Family
                      you've created on the Elderly Life platform. You can
                      invite different Main Users to join your Family.
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3 mb-md-4 mb-xxl-5">
                    <Badge className="d-flex align-items-center">
                      <h2>PU</h2>
                    </Badge>
                    <p className="para-list">
                      As a Main User you can access all the features of the
                      Elderly Life platform. You can add Support Users who can
                      act on your behalf.
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-3 mb-md-4 mb-xxl-5">
                    <Badge className="d-flex align-items-center">
                      <h2>SU</h2>
                    </Badge>
                    <p className="para-list">
                      As a Support User you can support different Main Users
                      across the Families by managing the activities on the
                      platform on their behalf.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className="create-family">
          <Container>
            <p>Found our Product Efficient to manage all your expenses?</p>
            <a
              href="https://dev-app.elderlylife.co.uk/"
              className=""
              target="_blank"
            >
              <Button variant="info" className="custom-btn">
                Create a Family and Manage Your Life
              </Button>
            </a>
          </Container>
        </section> */}
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default Home;
