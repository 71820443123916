import React from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import elderlylife_logo_icon from "../assets/elderlylife_logo_icon.svg";
import "./header.css";

const Header = () => {
  const splitUrl = window.location.href.split("/");
  console.log(splitUrl);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="elderly"
        variant="light"
        className="elderly"
      >
        <Container>
          <Link className="navbar-brand " to="/comingsoon">
            <img
              alt="eldery life"
              src={elderlylife_logo_icon}
              className="d-inline-block align-top logo"
            />
          </Link>
          <Navbar.Brand href="#home"></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/home">
                {splitUrl[3] === "home" ? (
                  <div className="activeBtn">Home</div>
                ) : (
                  <div>Home</div>
                )}
              </Link>
              {/* <Link className="nav-link" to="/about-us">
                About Us
              </Link> */}
              {/* <Link className="nav-link" to="/products">
                Products
              </Link> */}
              {/* <Link className="nav-link" to="/partners">
                Partners
              </Link>
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link> */}
              <Link className="nav-link" to="/our-Mission">
                {splitUrl[3] === "our-Mission" ? (
                  <div className="activeBtn">Our Mission</div>
                ) : (
                  <div>Our Mission</div>
                )}
              </Link>
              <Link className="nav-link" to="/how-It-Works">
                {splitUrl[3] === "how-It-Works" ? (
                  <div className="activeBtn">How It Works</div>
                ) : (
                  <div>How It Works</div>
                )}
              </Link>
              <Link className="nav-link" to="/plans">
                {splitUrl[3] === "plans" ? (
                  <div className="activeBtn">Plans</div>
                ) : (
                  <div>Plans</div>
                )}
              </Link>
            </Nav>
            <a
              href="https://dev.elderlylife.co.uk/"
              className="btn btn-info"
              target="_blank"
              rel="noreferrer"
            >
              Log In
            </a>
            {/* <Link to={{ pathname: "https://example.com" }} target="_blank">sdf</Link> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
