import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import eLderlylife_logo from "../assets/elderlylife-logo.svg";
import fb from "../assets/social-icons/fb.svg";
import linkedin from "../assets/social-icons/linkedin.svg";
import twitter from "../assets/social-icons/twitter.svg";
import whatsapp from "../assets/social-icons/whatsapp.svg";

import "./footer.css";
window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "nedui962",
};
// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/nedui962";
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();
const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <Col sm={8} md={12} lg={4} className="order-0">
              <img
                alt="eLderlylife_logo"
                src={eLderlylife_logo}
                className="d-inline-block align-top logo"
              />

              <p className="mt-4 intro">
                Elderly life helps all the people manage their spending and
                reminders on a single platform.
              </p>
            </Col>
            <Col sm={8} md={6} lg={4} className="order-3 order-sm-2 order-md-1">
              <h4 className="ft-heading mb-4">Reach out to us</h4>
              <p className="address-head mb-1">Address</p>
              <p className="mb-0">71 Main St, ACASTER SELBY, YO5 6ZT</p>
              <p className="mb-0">contact@elderlylife.com</p>
              <p>078 2821 1593</p>
              <Nav className="social-icons">
                <Nav.Link href="/">
                  <img alt="elderly_life facebook" src={fb} />
                </Nav.Link>
                <Nav.Link href="/">
                  <img alt="elderly_life linkedin" src={linkedin} />
                </Nav.Link>
                <Nav.Link href="/">
                  <img alt="elderly_life twitter" src={twitter} />
                </Nav.Link>
                <Nav.Link href="/">
                  <img alt="elderly_life whatsapp" src={whatsapp} />
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2} className="order-1">
              <Nav className="flex-column">
                <Nav.Link className="nav-link" href="/about-us">
                  About Us
                </Nav.Link>
                <Nav.Link className="nav-link" href="/products">
                  Products
                </Nav.Link>
                <Nav.Link className="nav-link" href="/partners">
                  Partners
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} sm={4} md={3} lg={2} className="order-2">
              <Nav className="flex-column">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
                <Link className="nav-link" to="/sitemap">
                  Sitemap
                </Link>
              </Nav>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-bg">
        <Container>
          <div className="ft-btm d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div>
              <p>
                &copy; {new Date().getFullYear()} Elderly Life Ltd. All rights
                reserved.
              </p>
            </div>
            <div>
              <Nav className="me-auto">
                <Link className="nav-link" to="/#">
                  Terms and conditions
                </Link>
                <Link className="nav-link" to="/privacy-policy">
                  Privacy policy
                </Link>
                {/* <a href="#" className="main-footer">
                  <li className="footer-menu">Terms and conditions</li>
                </a>
                <a href="#" className="main-footer">
                  <li className="footer-menu">Privacy policy</li>
                </a> */}
              </Nav>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;
