import React from "react";
import { Container, Row, Col, Button, Card, Badge, Nav } from "react-bootstrap";
import Header from "../../../Components/header/Header";
import Footer from "../../../Components/footer/Footer";
import business_brainstorming from "../../assets/business-brainstorming.jpg";

import "./about.css";

const About = () => {
  return (
    <>
      <Header />
      <div className="aboutus">
        <section className="about-intro">
          <Container>
            <Row>
              <Col md={5}>
                <div className="d-flex align-items-center h-100">
                  <div>
                    <h1 className="theme-color tittle mb-3">About Us</h1>
                    <p>
                      We are a group of Motivated and Committed individuals
                      focused on improving the lifestyles of the Elderly people
                      in our country,
                    </p>
                    <p>
                      Making Impact by help in largest growing elderly community
                      is our primary Motto
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <img
                  alt="business brainstorming"
                  className="img-fluid"
                  style={{ borderBottomRightRadius: "15px" }}
                  src={business_brainstorming}
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our-vision">
          <div class="elderlife-vision"></div>
          <Container>
            <Row>
              {/* <Col md={6}> */}
              {/* <img alt='business brainstorming' className='img-fluid' src={business_brainstorming} /> */}
              {/* </Col>   */}
              <Col xs={6} className="offset-6">
                <div className="ps-3 pt-4 pad-btm">
                  <span className="family-matters">#Family Matters</span>
                  <h2 className="theme-color inner-tittle mt-3 mb-3">
                    Our Vision
                  </h2>
                  <p>
                    We are always committed to one thing “ Betterment of
                    Lifestyle for Elder people “.
                  </p>
                  <p>
                    <b>Trustworthy, Reliability & Adaptability</b> are our
                    guiding principles to achieve our vision.
                  </p>
                  <p>
                    We are looking forward to ensure all the digital reminders
                    of the Elderly people are handled at one place and save
                    their time to concentrate on their more important works.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="contact-vision">
          <div className="exited-vision"></div>
          <Container>
            <Row>
              {/* <Col md={6}> */}
              {/* <img alt='business brainstorming' className='img-fluid' src={business_brainstorming} /> */}
              {/* </Col>   */}
              <Col xs={6} className="py-md-5">
                <div className="px-2 px-md-3 py-md-5">
                  <h2 className="inner-tittle mt-3 mb-3">
                    Exited about our Vision
                  </h2>
                  <p>
                    Want to discuss and ideate on improving our vision. We are
                    always open to discuss and achieve out vision in
                    collaboration.
                  </p>
                  <Nav.Link href="/contact-us">
                    <Button variant="info" className="custom-btn mt-md-4 ">
                      Contact Us
                    </Button>
                  </Nav.Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;
