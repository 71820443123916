import React from "react";
import { Container, ListGroup } from "react-bootstrap";
// import Header from '../../../Components/header/Header';
// import Footer from '../../../Components/footer/Footer';
import Header from "../../../Components/header/Logoheader";
import Footer from "../../../Components/footer/FooterBottom";
import privacy_policy from "../../assets/privacy_policy.png";
import "./privacypolicy.css";

const PrivacyPolicy = () => {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      <section className="content-section">
        <Container>
          <div className="privacy_policy mb-4">
            <h1>Privacy Policy</h1>
          </div>
          <h5>Privacy Policy</h5>
          <p>Effective Date: January 10, 2019 </p>
          <p>
            Elderly Life Private Limited ("us", "we", or "our") operates the
            ElderlyLife.com website (the "Service").
          </p>
          <p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data. Our Privacy Policy for
            Elderly Life Private Limited is based on the Free Privacy Policy
            Template Website.
          </p>
          <p>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from
            elderlylife.com
          </p>

          <h5 className="mt-4">Information Collection and Use</h5>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>

          <h5 className="mt-4">Types of Data Collected</h5>
          <small
            style={{ fontWeight: "700", color: "#808080", fontSize: "16px" }}
          >
            Personal Data
          </small>
          <p className="mt-1">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>

          <ListGroup className="br-none mb-3">
            <ListGroup.Item>Email address</ListGroup.Item>
            <ListGroup.Item>First name and last name</ListGroup.Item>
            <ListGroup.Item>Phone number</ListGroup.Item>
            <ListGroup.Item>Cookies and Usage Data</ListGroup.Item>
          </ListGroup>

          <small
            style={{ fontWeight: "700", color: "#808080", fontSize: "16px" }}
          >
            Usage Data
          </small>
          <p className="mt-2">
            We may also collect information how the Service is accessed and used
            ("Usage Data"). This Usage Data may include information such as your
            computer's Internet Protocol address (e.g. IP address), browser
            type, browser version, the pages of our Service that you visit,the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.
          </p>

          <small
            style={{ fontWeight: "700", color: "#808080", fontSize: "16px" }}
          >
            Tracking & Cookies Data
          </small>
          <p className="mt-2">
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information.
          </p>
          <p>
            Cookies are files with small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Tracking technologies also used
            are beacons, tags, and scripts to collect and track information and
            to improve and analyze our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>

          <ListGroup className="br-none mb-3">
            <ListGroup.Item>
              <b>Session Cookies.</b> We use Session Cookies to operate our
              Service.
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Preference Cookies.</b> We use Preference Cookies to remember
              your preferences and various settings.
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Security Cookies.</b> We use Security Cookies for security
              purposes.
            </ListGroup.Item>
          </ListGroup>

          <h5 className="mt-4">Use of Data</h5>
          <p>
            Elderly Life Limited uses the collected data for various purposes:
          </p>
          <ListGroup className="br-none mb-3">
            <ListGroup.Item>To provide and maintain the Service</ListGroup.Item>
            <ListGroup.Item>
              To notify you about changes to our Service
            </ListGroup.Item>
            <ListGroup.Item>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </ListGroup.Item>
            <ListGroup.Item>
              To provide customer care and support
            </ListGroup.Item>
            <ListGroup.Item>
              To provide analysis or valuable information so that we can improve
              the Service
            </ListGroup.Item>
            <ListGroup.Item>To monitor the usage of the Service</ListGroup.Item>
            <ListGroup.Item>
              To detect, prevent and address technical issues
            </ListGroup.Item>
          </ListGroup>

          <h5 className="mt-4">Transfer of Data</h5>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside India and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to India and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            Elderly Life Limited will take all steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organization or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>

          <h5 className="mt-4">Disclosure of Data</h5>
          <small
            style={{ fontWeight: "700", color: "#808080", fontSize: "16px" }}
          >
            Legal Requirements
          </small>
          <p className="mt-1">
            Elderly Life Limited may disclose your Personal Data in the good
            faith belief that such action is necessary to:
          </p>

          <ListGroup className="br-none mb-3">
            <ListGroup.Item>To comply with a legal obligation</ListGroup.Item>
            <ListGroup.Item>
              To protect and defend the rights or property of Elderly Life
              Limited
            </ListGroup.Item>
            <ListGroup.Item>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </ListGroup.Item>
            <ListGroup.Item>
              To protect the personal safety of users of the Service or the
              public
            </ListGroup.Item>
            <ListGroup.Item>To protect against legal liability</ListGroup.Item>
          </ListGroup>

          <h5 className="mt-4">Security of Data</h5>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>

          <h5 className="mt-4">Service Providers</h5>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Data only to
            perform these To Do's on our behalf and are obligated not to
            disclose or use it for any other purpose.
          </p>

          <h5 className="mt-4">Analytics</h5>
          <p className="mt-1">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <small
            style={{ fontWeight: "700", color: "#808080", fontSize: "16px" }}
          >
            Google Analytics
          </small>
          <p className="mt-2">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>
          <p>
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js, and dc.js) from sharing information
            with Google Analytics about visits activity.
          </p>
          <p>
            For more information on the privacy practices of Google, please
            visit the Google Privacy & Terms web page:
            <span className="d-block">
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </span>
          </p>

          <h5 className="mt-4">Link To Other Sites</h5>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h5 className="mt-4">Contact Us</h5>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p>
            By email:{" "}
            <a href="mailto:hello@elfderlylife.com">hello@elfderlylife.com</a>
          </p>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
