import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../../Components/header/Header";
import "./contact.css";
import contact_us from "../../assets/contact_us.svg";
import FooterBottom from "../../footer/FooterBottom";

const Contact = () => {
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // const phoneno = /^\d{10}$/;
    if (!values.name) {
      errors.name = "Username is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    if (!values.mobile) {
      errors.mobile = "mobile number required!";
    }
    //  else if (!phoneno.test(values.mobile)) {
    //   errors.mobile = "This is not a valid mobile number!";
    // }

    if (!values.subject) {
      errors.subject = "select subject required!";
    }

    // if (!values.password) {
    //   errors.password = "Password is required";
    // } else if (values.password.length < 4) {
    //   errors.password = "Password must be more than 4 characters";
    // } else if (values.password.length > 10) {
    //   errors.password = "Password cannot exceed more than 10 characters";
    // }
    return errors;
  };

  return (
    <>
      <Header />
      <section className="contact-us">
        <Container>
          <Row>
            <Col md={12} lg={4} xl={4} className="mb-3 mb-lg-0">
              <h1 className="theme-color contact-head">Get in Touch</h1>
              <p>
                Feel free to contact us on your queries and we will get back to
                you as soon as possible
              </p>
              <img
                alt="elderly life contact"
                src={contact_us}
                className="img-fluid"
              />
            </Col>
            <Col md={12} lg={8} xl={7} className="offset-xl-1">
              <h5 className="mb-4">
                <b>Send us a message</b>
              </h5>

              <Form onSubmit={handleSubmit} autoComplete="off">
                <Row className="mb-3">
                  <Col md={6} className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        value={formValues.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <p className="errors">{formErrors.name}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <p className="errors">{formErrors.email}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name="mobile"
                        type="text"
                        onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false;"
                        pattern="/^(\+\d{1,3}[- ]?)?\d{10}$/"
                        value={formValues.mobile}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <p className="errors">{formErrors.mobile}</p>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Subject</Form.Label>
                      <Form.Select
                        name="subject"
                        aria-label="Default select example"
                        value={formValues.subject}
                        onChange={handleChange}
                      >
                        <option></option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </Form.Group>
                    <p className="errors">{formErrors.subject}</p>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        name="message"
                        as="textarea"
                        rows={3}
                        maxLength="255"
                        value={formValues.message}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Button
                      variant="info"
                      className="custom-btn submit"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <FooterBottom />
    </>
  );
};

export default Contact;
