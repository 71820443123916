import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Badge, Nav } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
// import business_brainstorming from "../../assets/business-brainstorming.jpg";
import howItWorks1 from "../../assets/Picture5.svg";
import howItWorks2 from "../../assets/Picture6.svg";
import howItWorks3 from "../../assets/Picture7.png";
import howItWorks4 from "../../assets/Picture8.svg";
import Elimg from "../../assets/Planning-&-Organization-tool.jpg";
import subscription from "../../assets/subscriptionempty.svg";
import warranties from "../../assets/warranties-empty.svg";
import giftcards from "../../assets/gift-cards-empty.svg";
import task from "../../assets/task-empty.svg";
import benefits from "../../assets/benefits-empty.svg";
import comparesave from "../../assets/compare-save.svg";
import { Link } from "react-router-dom";

import "./howItWorks.css";
import Loader from "../../loader/Loader";

const HowItWorks = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);
  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="aboutus">
          <div className="header-box">
            Built on 4 main principles – giving you <b>control</b>, keeping you{" "}
            <b>Reminded</b>, helping you <b>Save Money</b> and finally giving
            you the ability for a helping hand with <b>Support</b> from your
            family, Loved Ones and Friends
          </div>
          <section className="about-intro">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex align-items-center flex-column m-auto"
                >
                  <img
                    alt="howItWorks1"
                    className="img-fluid position-relative image-style"
                    src={howItWorks1}
                  />
                  <div className="image-text">
                    <span style={{ color: "grey" }}>Giving</span>
                    <span style={{ color: "black" }}>You</span>
                    <span style={{ color: "#36f1cc" }}>Control</span>
                  </div>
                </Col>
                <Col md={6} style={{ backgroundColor: "#f4f4f4" }}>
                  <div className="d-flex h-100 flex-column">
                    <>
                      {/* <div>
                    <div className="text-title mb-3">
                      Our Mission, Straight from the Heart
                    </div>
                    <p className="mb-3">
                      Hey there! We all know how rockstar our elderly folks are.
                      They've spun tales that shaped our world, and as they hit
                      their prime years, it's time we lend a hand to ensure
                      they've got all they need.
                    </p>
                    <div className="text-title mb-3">The Lowdown</div>
                    <p className="mb-3">
                      In the UK, we have a whopping 13 million people aged 65
                      and up, making up 20% of our crew. And by 2040? We're
                      looking at a rise to 18 million! But here's the kicker:
                      loads of these incredible individuals are living life
                      solo, and too many are battling to make ends meet. We need
                      to turn that around.
                    </p>

                    <div className="text-title mb-3">Enter Our App</div>
                    <p className="mb-3">
                      This isn't just another app. It's like a digital helping
                      hand from your family or friends. Created with a whole lot
                      of heart, it's here to be a game-changer. Whether it's the
                      elderly using it themselves or their loved ones jumping in
                      to lend a hand, it's all about staying connected, getting
                      organised, and making that money stretch further.
                    </p>
                  </div> */}
                      {/* <Col lg={6} className=" ">
                    <Card className="d-flex flex-row border-0 align-items-md-center align-items-lg-start">
                      <Card.Img
                        alt="subscription"
                        variant="top"
                        src={subscription}
                      />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Outgoings
                        </Card.Title>
                        <Card.Text>
                          You will never miss a renewal date for any of the
                          Outgoings you use.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                      {/* <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img
                        alt="warranties"
                        variant="top"
                        src={warranties}
                      />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Warranties
                        </Card.Title>
                        <Card.Text>
                          Stay relieved when all your warranties are handled by
                          us at one place.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                      {/* <Col lg={6} className="">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img alt="giftcards" variant="top" src={giftcards} />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Gift cards & Vouchers
                        </Card.Title>
                        <Card.Text>
                          Never lose the benefits of a gift, by keeping a steady
                          track on it.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} className="">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img
                        alt="warranties"
                        variant="top"
                        src={warranties}
                      />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Warranties
                        </Card.Title>
                        <Card.Text>
                          Stay relieved when all your warranties are handled by
                          us at one place.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                      {/* <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img alt="task" variant="top" src={task} />
                      <Card.Body>
                        <Card.Title className="theme-head2">To Do's</Card.Title>
                        <Card.Text>
                          Stay on top of your important To Do's with timely
                          reminders on the platform.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                      {/* <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img alt="benefits" variant="top" src={benefits} />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Benefits
                        </Card.Title>
                        <Card.Text>
                          Get the exclusive list of benefits curated towards
                          your spending trends.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                      {/* <Col lg={6} className="mb-3 mb-md-4 mb-xxl-5">
                    <Card className="d-flex flex-row border-0">
                      <Card.Img
                        alt="compare and save"
                        variant="top"
                        src={comparesave}
                      />
                      <Card.Body>
                        <Card.Title className="theme-head2">
                          Compare and Save
                        </Card.Title>
                        <Card.Text>
                          Stay timely reminded with all your important To Do's
                          you have added on the platform.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col> */}
                    </>
                    <>
                      <div className="d-flex gap-3 my-3">
                        <img
                          alt="subscription"
                          variant="top"
                          src={subscription}
                          className="right-box"
                        />
                        <div
                          className="gap-2 d-flex flex-column"
                          style={{ fontSize: "16px" }}
                        >
                          <b>Outgoings</b>
                          <div>
                            Add all your outgoings, this includes all your costs
                            you spend – your subscriptions, bills, everything
                            you pay consistently for. Keeping you completely on
                            track of all your outgoings.
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 my-3">
                        <img
                          alt="giftcards"
                          variant="top"
                          src={giftcards}
                          className="right-box"
                        />
                        <div
                          className="gap-2 d-flex flex-column"
                          style={{ fontSize: "16px" }}
                        >
                          <b>Gift Cards and Vouchers</b>
                          <div>
                            Add your gift cards and vouchers to platform,
                            ensuring you never miss out on using your remaining
                            savings.
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 my-3">
                        <img
                          alt="warranties"
                          variant="top"
                          src={warranties}
                          className="right-box"
                        />
                        <div
                          className="gap-2 d-flex flex-column"
                          style={{ fontSize: "16px" }}
                        >
                          <b>Warranties</b>
                          <div>
                            Add all your item warranties to ensure that when
                            something does go wrong you can easily get it fixed
                            or get the support you need.
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="about-intro">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex align-items-center flex-column m-auto"
                >
                  <img
                    alt="howItWorks2"
                    className="img-fluid position-relative image-style"
                    src={howItWorks2}
                  />
                  <div className="image-text">
                    <span style={{ color: "grey" }}>Keeping</span>
                    <span style={{ color: "black" }}>You</span>
                    <span style={{ color: "#36f1cc" }}> Reminded</span>
                  </div>
                </Col>
                <Col md={6} style={{ backgroundColor: "#f4f4f4" }}>
                  <div className="d-flex h-100 flex-column">
                    <>
                      <div className="d-flex gap-3 my-3 flex-column align-items-center text-center">
                        <img
                          alt="task"
                          variant="top"
                          src={task}
                          className="right-box"
                        />
                        <div>
                          The heart of the
                          <span
                            className="mx-1"
                            style={{ color: "#36f1cc", fontWeight: "700" }}
                          >
                            {" "}
                            EL platform{" "}
                          </span>
                          is our reminder panel which enables{" "}
                          <span
                            className="mx-1"
                            style={{ color: "black", fontWeight: "700" }}
                          >
                            reminders
                          </span>{" "}
                          to be sent – so you never forget an important{" "}
                          <span
                            className="mx-1"
                            style={{ color: "#36f1cc", fontWeight: "700" }}
                          >
                            {" "}
                            date
                          </span>
                        </div>
                        <div style={{ color: "#3030b3", fontWeight: "700" }}>
                          To Do's
                        </div>
                        <div>
                          The{" "}
                          <span
                            className="mx-1"
                            style={{ color: "#36f1cc", fontWeight: "700" }}
                          >
                            To Do’s
                          </span>
                          feature allows you to set specific tasks for you or
                          anyone on your{" "}
                          <span
                            className="mx-1"
                            style={{ color: "#36f1cc", fontWeight: "700" }}
                          >
                            {" "}
                            EL platform
                          </span>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="about-intro">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex align-items-center flex-column m-auto"
                >
                  <img
                    alt="howItWorks3"
                    className="img-fluid position-relative image-style"
                    src={howItWorks3}
                  />
                  <div className="image-text">
                    <span style={{ color: "grey" }}>Providing</span>
                    <span style={{ color: "black" }}>You</span>
                    <span style={{ color: "#36f1cc" }}> Support</span>
                  </div>
                </Col>
                <Col
                  md={6}
                  style={{
                    backgroundColor: "#f4f4f4",
                    padding: "2rem",
                  }}
                >
                  <div className="d-flex h-100">
                    <div className="providing-box">
                      <div>
                        Every plan comes with a
                        <span
                          className="mx-1"
                          style={{ color: "#36f1cc", fontWeight: "700" }}
                        >
                          support
                        </span>{" "}
                        user. This user can be anyone you want to help you,
                        helping you means entering all your data{" "}
                        <span
                          className="mx-1"
                          style={{ color: "black", fontWeight: "700" }}
                        >
                          for you
                        </span>
                        , so you can{" "}
                        <span
                          className="mx-1"
                          style={{ color: "black", fontWeight: "700" }}
                        >
                          {" "}
                          sit back and be reminded{" "}
                        </span>
                        on everything you need to know.{" "}
                      </div>
                      <div>
                        The{" "}
                        <span
                          className="mx-1"
                          style={{ color: "#36f1cc", fontWeight: "700" }}
                        >
                          {" "}
                          To Do’s{" "}
                        </span>{" "}
                        feature is built on the{" "}
                        <span
                          className="mx-1"
                          style={{ color: "#36f1cc", fontWeight: "700" }}
                        >
                          support
                        </span>{" "}
                        concept, so if you need your{" "}
                        <span
                          className="mx-1"
                          style={{ color: "#36f1cc", fontWeight: "700" }}
                        >
                          support
                        </span>{" "}
                        user to action a task, just{" "}
                        <span
                          className="mx-1"
                          style={{ color: "black", fontWeight: "700" }}
                        >
                          {" "}
                          let them know{" "}
                        </span>{" "}
                        via the feature! This could be simply asking them to
                        pick up your prescription or can you get me some milk?
                        Anything you need done on a specific date.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="about-intro">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex align-items-center flex-column m-auto"
                >
                  <img
                    alt="howItWorks4"
                    className="img-fluid position-relative image-style"
                    src={howItWorks4}
                  />
                  <div className="image-text">
                    <span style={{ color: "grey" }}>Saving</span>
                    <span style={{ color: "black" }}>You</span>
                    <span style={{ color: "#36f1cc" }}> Money</span>
                  </div>
                </Col>
                <Col md={6} style={{ backgroundColor: "#f4f4f4" }}>
                  <div className="d-flex h-100 flex-column">
                    <>
                      <div className="d-flex gap-3 my-3">
                        <img
                          alt="comparesave"
                          variant="top"
                          src={comparesave}
                          className="right-box"
                        />
                        <div
                          className="gap-2 d-flex flex-column"
                          style={{ fontSize: "16px" }}
                        >
                          <b>Compare and Save</b>
                          <div className="d-flex gap-2 flex-column">
                            <div>
                              By using our exclusive compare and save portal,
                              you will be able to get the best deals on all your
                              key insurance products{" "}
                            </div>
                            <div>Many more products coming soon!!!</div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex gap-3 my-3">
                        <img
                          alt="benefits"
                          variant="top"
                          src={benefits}
                          className="right-box"
                        />
                        <div
                          className="gap-2 d-flex flex-column"
                          style={{ fontSize: "16px" }}
                        >
                          <b>Benefits</b>
                          <div className="d-flex gap-2 flex-column">
                            <div>
                              Exclusive Benefits Coming Soon for all Elderly
                              Life members.
                            </div>
                            <div>
                              We are constantly working with partners to get
                              more special offers for our members.
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default HowItWorks;
