import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, Badge, Nav } from "react-bootstrap";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
// import business_brainstorming from "../../assets/business-brainstorming.jpg";
// import ourMission1 from "../../assets/ourMission1.svg";
// import ourMission2 from "../../assets/ourMission2.svg";
// import ourMission3 from "../../assets/ourMission3.png";

import ourMission1 from "../../assets/3441210.jpg";
import ourMission2 from "../../assets/8358.jpg";
import ourMission3 from "../../assets/upx1_dbyj_230116.jpg";

import "./ourMission.css";
import Loader from "../../loader/Loader";

const OurMission = () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="aboutus">
          <section className="about-intro pb-1">
            <Container>
              <Row>
                <Col md={6} className="d-flex align-items-center">
                  <img
                    alt="ourMission1"
                    className="img-fluid position-relative"
                    src={ourMission1}
                    style={{ width: "25rem" }}
                  />
                </Col>
                <Col md={6}>
                  <div className="d-flex h-100">
                    <div>
                      <div className="text-title mb-3">
                        Our Mission, Straight from the Heart
                      </div>
                      <p className="mb-3">
                        Hey there! We all know how rockstar our elderly folks
                        are. They've spun tales that shaped our world, and as
                        they hit their prime years, it's time we lend a hand to
                        ensure they've got all they need.
                      </p>
                      <div className="text-title mb-3">The Lowdown</div>
                      <p className="mb-3">
                        In the UK, we have a whopping 13 million people aged 65
                        and up, making up 20% of our crew. And by 2040? We're
                        looking at a rise to 18 million! But here's the kicker:
                        loads of these incredible individuals are living life
                        solo, and too many are battling to make ends meet. We
                        need to turn that around.
                      </p>

                      <div className="text-title mb-3">Enter Our App</div>
                      <p className="mb-3">
                        This isn't just another app. It's like a digital helping
                        hand from your family or friends. Created with a whole
                        lot of heart, it's here to be a game-changer. Whether
                        it's the elderly using it themselves or their loved ones
                        jumping in to lend a hand, it's all about staying
                        connected, getting organised, and making that money
                        stretch further.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="about-intro pb-1">
            <Container>
              <Row>
                <Col md={6} className="d-flex align-items-center">
                  <img
                    alt="ourMission2"
                    className="img-fluid position-relative"
                    src={ourMission2}
                    style={{ width: "30rem" }}
                  />
                </Col>
                <Col md={6}>
                  <div className="d-flex h-100">
                    <div>
                      <div className="text-title mb-3">
                        Family, Friends, and the App - The Dream Team
                      </div>
                      <p className="mb-3">
                        Our App is like having your family or friends in your
                        pocket. They can jump in, offer a hand or keep an eye
                        out to ensure everything's ticking along nicely. Need to
                        track expenses? Check, Sort out upcoming events. Done.
                        It's like having your team on standby, all through the
                        App.
                      </p>
                      <div className="text-title mb-3">
                        Making Money Less... Well, Tricky
                      </div>
                      <p className="mb-3">
                        Times are tight, and we want to ensure our elderly
                        aren't feeling the squeeze. So, we've set up our App to
                        be that go-to place for:
                        <ul class="abc">
                          <li>Keeping tabs on all your expenses</li>

                          <li>
                            Comparing best prices through the comparison portal
                          </li>

                          <li>
                            Hacks to trim down bills and grab affordable
                            essentials
                          </li>

                          <li>Utilise your vouchers and coupons</li>

                          <li>Making sure you use your warranties </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="about-intro pb-1">
            <Container>
              <Row>
                <Col md={6} className="d-flex align-items-center">
                  <img
                    alt="ourMission3"
                    className="img-fluid position-relative"
                    src={ourMission3}
                    style={{ width: "20rem" }}
                  />
                </Col>
                <Col md={6}>
                  <div className="d-flex h-100">
                    <div>
                      <div className="text-title mb-3">Never Miss a Beat</div>
                      <p className="mb-3">
                        Juggling life can get hectic, and sometimes, things slip
                        through the cracks. That's where our App steps in:
                        <ul class="abc">
                          <li>
                            The reminder panel lets you customise when you want
                            to receive a reminder for all your needs
                          </li>
                          <li>
                            Alerts allow you to know upcoming subscription
                            renewals, warranties for products you have, vouchers
                            and gifts cards you can use
                          </li>
                          <li>
                            The supporting concept allows you to have family,
                            friends supporting with your to do’s such as picking
                            up a prescription or taking you to an appointment
                          </li>
                          <li>
                            All those little things you want to remember, all
                            sorted.
                          </li>
                        </ul>
                      </p>

                      <div className="text-title mb-3">The Nitty-Gritty</div>
                      <p className="mb-3">
                        In this mad world, we're rooting for connections and
                        memories and ensuring our roots — our elders — have the
                        smoothest ride. If your Elderly and need to get
                        organised, planned and save money or you want to support
                        a parent, grandparent or a loved one - With our App,,
                        it's about making every day brighter and more sorted.
                        Let's dive in and let us Make Life Simple.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default OurMission;
