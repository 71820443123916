import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <section className="footer-bg single-ft">
      <Container>
        <div className="ft-btm d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div>
            <p>
              &copy; {new Date().getFullYear()} Elderly Life Ltd. All rights
              reserved.
            </p>
          </div>
          <div>
            <Nav className="me-auto">
              <Link className="nav-link" to="/terms-and-condition">
                Terms and conditions
              </Link>
              <Link className="nav-link" to="/privacy-policy">
                Privacy policy
              </Link>
              {/* <a href="#" className="main-footer">
                <li className="footer-menu">Terms and conditions</li>
              </a>
              <a href="#" className="main-footer">
                <li className="footer-menu">Privacy policy</li>
              </a> */}
            </Nav>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FooterBottom;
