import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tabs,
  Tab,
  Nav,
} from "react-bootstrap";
import Header from "../../../Components/header/Header";
import Footer from "../../../Components/footer/Footer";
import "./products.css";
import Elimg from "../../assets/organization_tool.jpg";
import monitoring from "../../assets/monitoring.svg";
import reminder from "../../assets/reminder.svg";
import save_efficiently from "../../assets/save_efficiently.svg";
import free_trail from "../../assets/free-trial.svg";

const Products = () => {
  const [key, setKey] = useState("Monthly");
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "nedui962",
  };
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/nedui962'
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nedui962";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  return (
    <>
      <Header />
      <div className="products">
        <section className="hero-page">
          <Container>
            <Row>
              <Col lg={6} className="mb-4 mb-lg-0">
                <div className="caption">
                  <div>
                    <span className="family-matters">#Family Matters</span>
                    <h1 className="mb-3">
                      Organization tool that let you stay ahead
                    </h1>
                    <p>
                      A Delightful Product to make sure you are enforced with
                      all the necessities and are managed at one stop.
                    </p>
                    <a
                      href="https://dev-app.elderlylife.co.uk/"
                      className=""
                      target="_blank"
                    >
                      <Button variant="info" className="custom-btn">
                        View Account Plans
                      </Button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="text-start text-lg-end">
                <img
                  alt="Planning & Organization tool for the elderly"
                  src={Elimg}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="activities">
          <Container>
            <h4 className="theme-color ft-bold text-start text-lg-center">
              An intuitive tool to make sure you are on the top of all your
              activities
            </h4>
            <p className="intro-para text-start text-lg-center mb-4">
              Our tool will help you get reminded for almost all the activities
              that will help you manage your time better.
            </p>

            <Row>
              <Col md={6} lg={4} className="mb-3 mb-md-0">
                <Card className="border-0">
                  <Card.Body>
                    <Card.Img
                      className="mb-3"
                      alt="elderly life monitoring"
                      src={monitoring}
                    />
                    <Card.Title>
                      <b>Track Efficiently</b>
                    </Card.Title>
                    <Card.Text>
                      Track all your reminder activities like To Do's,
                      Outgoings, warranties, gift cards & vouchers.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} lg={4} className="mb-3 mb-md-0">
                <Card className="border-0">
                  <Card.Body>
                    <Card.Img
                      className="mb-3"
                      alt="elderly life monitoring"
                      src={reminder}
                    />
                    <Card.Title>
                      <b>Get Reminded Ahead</b>
                    </Card.Title>
                    <Card.Text>
                      We make all your important To Do's are reminded on time
                      and spend your precious time on other activities.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={12} lg={4} className="mb-3 mb-md-0 mt-md-4 mt-lg-0">
                <Card className="border-0">
                  <Card.Body>
                    <Card.Img
                      className="mb-3"
                      alt="elderly life monitoring"
                      src={save_efficiently}
                    />
                    <Card.Title>
                      <b>Save Effectively</b>
                    </Card.Title>
                    <Card.Text>
                      We make you find the right choice at a right price and
                      help you in financial decisions.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="account-plans">
          <Container>
            <div className="card-bg">
              <Row>
                <Col lg={7} md={12} sm={12}>
                  <h4 className="ft-bold mt-4">Elderly Life Account Plans</h4>
                  <p className="intro-para mb-4">
                    You can always choose a suitable plan for your Family
                    account on Elderly Life. All the below plans will have
                    access to all the <span className="module">modules</span> of
                    Elderly Life Platform.
                  </p>
                </Col>
                <Col lg={5} md={12} sm={12} className="mb-4 mb-lg-4">
                  <div className="inner-box d-flex">
                    <img
                      alt="7 days free trail"
                      className="img-fluid"
                      src={free_trail}
                    />
                    <div className="ps-4 pe-4">
                      <h5 className="ft-bold">7 Days free trial</h5>
                      <p>
                        Get free trial with any plan you purchase on Elderly
                        Life.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-2 mb-lg-4 mb-xl-5 plan-tabs"
              >
                <Tab eventKey="Monthly" title="Monthly" className="mt-3">
                  <Row>
                    <Col md={4} className="mb-3 mb-md-0">
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Single</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 19.99</b>
                            <span>/month</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">01 Main User</p>
                          <p className="mb-1">01 Support User</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={4} className="mb-3 mb-md-0">
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Couple</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 29.99</b>
                            <span>/month</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">02 Main Users</p>
                          <p className="mb-1">02 Support Users</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={4} className="mb-3 mb-md-0">
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Family</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 49.99</b>
                            <span>/month</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">05 Main Users</p>
                          <p className="mb-1">05 Support Users</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="Annual" title="Annual" className="mt-3">
                  <Row>
                    <Col md={4}>
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Single</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 229.99</b>
                            <span>/yearly</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">01 Main User</p>
                          <p className="mb-1">01 Support User</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={4}>
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Couple</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 349.99</b>
                            <span>/yearly</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">02 Main Users</p>
                          <p className="mb-1">02 Support Users</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={4}>
                      <Card className="border-0">
                        <Card.Body>
                          <Card.Text>Family</Card.Text>
                          <Card.Title className="theme-color mb-2 mb-lg-4">
                            <b>£ 549.99</b>
                            <span>/yearly</span>
                          </Card.Title>
                          <small className="mb-1">Benefits</small>
                          <p className="mb-1">05 Main Users</p>
                          <p className="mb-1">05 Support Users</p>
                          <div className="circle shape-circle"></div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </Container>
        </section>

        <section className="contact-vision">
          <div className="exited-vision"></div>
          <Container>
            <Row>
              {/* <Col md={6}> */}
              {/* <img alt='business brainstorming' className='img-fluid' src={business_brainstorming} /> */}
              {/* </Col>   */}
              <Col xs={6} className="py-md-5">
                <div className="px-2 px-md-3 py-md-5">
                  <h2 className="inner-tittle mt-3 mb-3">
                    Confused about our Account plans?
                  </h2>
                  <p>
                    Drop us an email to discuss about the plans and get more
                    information regarding them
                  </p>
                  <Nav.Link href="/contact-us">
                    <Button variant="info" className="custom-btn mt-md-4 ">
                      Contact Us
                    </Button>
                  </Nav.Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Products;
